import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { insertOrUpdateClinic } from "../state"
import { AddForm as Form } from "../style/globalStyles"
import Switch from "react-switch"
import S3FileUpload from "react-s3"
import ImageUploader from "react-images-upload"
import Moment from "moment"

import {
  ownershipTypes,
  inpatientTypes,
  clinicalServiceTypes,
} from "../pages/clinics/clinic"
export const SingleClinic = ({ clinicData = {} }) => {
  const dispatch = useDispatch()
  const [state, setState] = useState({})
  const [enable, setEnable] = useState(false)
  const [uploadingLogo, setUploadingLogo] = useState(false)
  useEffect(() => {
    setState({ ...clinicData })
  }, [])
  // console.log(state, clinicData)
  const handleChange = (e) => {
    // console.log(e)
    setState({ ...state, [e.target.name]: e.target.value })
    setEnable(true)
    // console.log("inside hc", state)
  }
  const handleActiveChange = (value) => {
    console.log(value)
    setState({ ...state, isActive: value })
    setEnable(true)

    console.log("inside hac", state)
  }
  const handleVerifiedChange = (value) => {
    console.log(value)
    setState({ ...state, isVerified: value })
    setEnable(true)

    console.log("inside hvc", state)
  }
  const createOptions = (inputArray, selectedValue) => {
    return inputArray.map((type) => (
      <option
        value={Number(type.lookupId)}
        key={type.lookupId}
        selected={type.lookupId === selectedValue}
      >
        {" "}
        {type.lookupDescription}{" "}
      </option>
    ))
  }

  //
  const onDrop = (file, type) => {
    // console.log(file);
    if (state.clinicName.split("").length >= 4) {
      setUploadingLogo(true)
      if (file[0] == null || file.length > 1) {
        return
      } else {
        Object.defineProperty(file[0], "name", {
          value: type + "." + file[0].type.split("/")[1],
          writable: false,
        })
        console.log(file)
        console.log(state)

        const config = {
          dirName: state.clinicName,
          bucketName: "swing-partner",
          region: "us-east-1",
          accessKeyId: "AKIAIBRBFN353IM4CGFQ",
          secretAccessKey: "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",
        }

        S3FileUpload.uploadFile(file[0], config)
          .then((response) => {
            console.log("url", response.location)
            setState({
              ...state,
              clinicLogo: response.location,
            })
            // setEnable(true)
            state.clinicId && dispatch(insertOrUpdateClinic(state))
            setUploadingLogo(false)

            // console.log("image URL", state.imageUrl);
          })
          .catch((err) => console.error(err))
      }
    } else {
      alert("   Enter clinic name to upload logo\n  \n   (Min 4 characters)")
    }
  }
  //

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(insertOrUpdateClinic(state))
    console.log("new clinic", state)
  }
  return (
    <Form onSubmit={handleSubmit} style={{}}>
      <div style={{ paddingRight: "1rem" }}>
        <div
          style={{
            marginBottom: "0rem",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            borderBottom: "1px solid lightgray",
          }}
        >
          <label htmlFor="clinicName">
            Name
            <input
              id="clinicName"
              name="clinicName"
              type="text"
              value={state.clinicName || ""}
              placeholder={"Enter clinic name"}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="clinicAddress">
            Address
            <input
              id="clinicAddress"
              name="clinicAddress"
              type="text"
              value={state.clinicAddress || ""}
              placeholder={"Enter clinic address"}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="clinicCity">
            City
            <input
              id="clinicCity"
              name="clinicCity"
              type="text"
              value={state.clinicCity || ""}
              placeholder={"Enter clinic city"}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="clinicState">
            State
            <input
              id="clinicState"
              name="clinicState"
              type="text"
              value={state.clinicState || ""}
              placeholder={"Enter clinic state"}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="clinicCountry">
            Country
            <input
              id="clinicCountry"
              name="clinicCountry"
              type="text"
              value={state.clinicCountry || ""}
              placeholder={"Enter clinic country"}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="clinicPincode">
            Pincode
            <input
              id="clinicPincode"
              name="clinicPincode"
              type="text"
              value={state.clinicPincode || ""}
              placeholder={"Enter clinic pincode"}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="clinicPhoneNo">
            Phone No
            <input
              id="clinicPhoneNo"
              name="clinicPhoneNo"
              type="text"
              value={state.clinicPhoneNo || ""}
              placeholder={"Enter clinic phone no"}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="clinicEmail">
            Email
            <input
              id="clinicEmail"
              name="clinicEmail"
              type="text"
              value={state.clinicEmail || ""}
              placeholder={"Enter clinic email"}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="clinicWebsiteAddress">
            Website Address
            <input
              id="clinicWebsiteAddress"
              name="clinicWebsiteAddress"
              type="text"
              value={state.clinicWebsiteAddress || ""}
              placeholder={"Enter clinic website address"}
              onChange={handleChange}
            />
          </label>

          <label>
            <ImageUploader
              withIcon={false}
              style={{
                height: "4rem",
              }}
              singleImage={true}
              //                            withPreview={state.mobileNo != null && state.mobileNo >= 10 ? true : false}
              fileContainerStyle={{
                boxShadow: "none",
                padding: "0",
                alignItems: "start",
                color: "lightgray",
                margin: "0",
              }}
              labelStyles={{
                padding: "0",
                marginTop: "0",
                color: "gray",
                fontSize: ".8rem",
              }}
              buttonStyles={{
                width: "100%",

                height: "2rem",
                borderRadius: "0",
                fontSize: ".8rem",
                margin: "0",
              }}
              label="Clinic Logo"
              name=""
              buttonText={
                state.clinicLogo === "" || state.clinicLogo === undefined
                  ? "Choose Logo"
                  : uploadingLogo
                  ? "Uploading Logo..."
                  : "Logo Uploaded"
              }
              onChange={(file) => onDrop(file, "clinicLogo")}
              imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
              maxFileSize={5242880}
            />
          </label>

          <label htmlFor="clinicalServiceType">
            Clinical Service Type
            <select
              style={{ marginTop: ".6rem" }}
              name="clinicalServiceType"
              // onChange={handleChange}
              onChange={(e) => {
                setState({
                  ...state,
                  [e.target.name]: Number(e.target.value),
                })
              }}
            >
              <option>Select Cilincal Service Type</option>

              {createOptions(clinicalServiceTypes, state.clinicalServiceType)}
            </select>
          </label>

          <label>
            Inpatient Type
            <select
              style={{ marginTop: ".6rem" }}
              name="inpatient"
              // onChange={handleChange}
              onChange={(e) => {
                setState({
                  ...state,
                  [e.target.name]: Number(e.target.value),
                })
              }}
            >
              <option>Select Inpatient Type</option>

              {createOptions(inpatientTypes, state.inpatient)}
            </select>
          </label>
          <p />
        </div>
        <br />
        <br />
        <h4>Clinic Owner</h4>
        <div
          style={{
            marginBottom: "2rem",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            borderBottom: "1px solid lightgray",
            width: "100%",
          }}
        >
          <label htmlFor="clinicOwnerName">
            Owner Name
            <input
              id="clinicOwnerName"
              name="clinicOwnerName"
              type="text"
              value={state.clinicOwnerName || ""}
              placeholder={"Enter clinic owner name"}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="ownerAddress">
            Address
            <input
              id="ownerAddress"
              name="ownerAddress"
              type="text"
              value={state.ownerAddress || ""}
              placeholder={"Enter owner address"}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="ownerCity">
            City
            <input
              id="ownerCity"
              name="ownerCity"
              type="text"
              value={state.ownerCity || ""}
              placeholder={"Enter owner city"}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="ownerState">
            State
            <input
              id="ownerState"
              name="ownerState"
              type="text"
              value={state.ownerState || ""}
              placeholder={"Enter owner state"}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="ownerCountry">
            Country
            <input
              id="ownerCountry"
              name="ownerCountry"
              type="text"
              value={state.ownerCountry || ""}
              placeholder={"Enter owner country"}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="ownerPincode">
            Pincode
            <input
              id="ownerPincode"
              name="ownerPincode"
              type="text"
              value={state.ownerPincode || ""}
              placeholder={"Enter owner pincode"}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="ownerPhoneNo">
            Phone No
            <input
              id="ownerPhoneNo"
              name="ownerPhoneNo"
              type="text"
              value={state.ownerPhoneNo || ""}
              placeholder={"Enter owner phone no"}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="ownerEmail">
            Email
            <input
              id="ownerEmail"
              name="ownerEmail"
              type="text"
              value={state.ownerEmail || ""}
              placeholder={"Enter owner email"}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="ownershipType">
            Ownership Type
            <select
              style={{ marginTop: ".6rem" }}
              name="ownershiptype"
              // onChange={handleChange}
              onChange={(e) => {
                setState({
                  ...state,
                  [e.target.name]: Number(e.target.value),
                })
              }}
            >
              <option>Select Ownership Type</option>

              {createOptions(ownershipTypes, state.ownershipType)}
            </select>
          </label>
        </div>
        <h4>Authorized Person</h4>

        <div
          style={{
            marginBottom: "2rem",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <label htmlFor="authorizedPersonName">
            Person Name
            <input
              id="authorizedPersonName"
              name="authorizedPersonName"
              type="text"
              value={state.authorizedPersonName || ""}
              placeholder={"Enter authorized person name"}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="authorizedContactNo">
            Contact No
            <input
              id="authorizedContactNo"
              name="authorizedContactNo"
              type="text"
              value={state.authorizedContactNo || ""}
              placeholder={"Enter authorized contact no"}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="authorizedEmail">
            Email
            <input
              id="authorizedEmail"
              name="authorizedEmail"
              type="text"
              value={state.authorizedEmail || ""}
              placeholder={"Enter authorized email"}
              onChange={handleChange}
            />
          </label>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "100%",
          height: "fit-content",
        }}
      >
        <label htmlFor="isActive">
          Active Status
          <br />
          <br />
          <Switch
            name="isActive"
            className="react-switch"
            onChange={handleActiveChange}
            checked={state.isActive}
          />
        </label>
        <label htmlFor="isVerified">
          Verified Status
          <br />
          <br />
          <Switch
            name="isVerified"
            className="react-switch"
            onChange={handleVerifiedChange}
            checked={state.isVerified}
          />
        </label>
        {/* <label htmlFor="isSubscription">
          Subscription Status
          <br />
          <br />
          <Switch
            name="isSubscription"
            className="react-switch"
            onChange={handleSubscribeChange}
            checked={state.isSubscription}
          />
        </label> */}
        <br />
        {state.clinicId && (
          <>
            <label htmlFor="mobileNo">
              Created
              <p>
                {state.created && Moment(state.created).format("DD MMM YYYY")}
              </p>
            </label>
            <label htmlFor="mobileNo">
              Last updated
              <p>
                {state.updated
                  ? Moment(state.updated).format("DD MMM YYYY")
                  : "NA"}
              </p>
            </label>
            <br />
          </>
        )}
        <button
          type="submit"
          aria-disabled={enable ? true : false}
          style={{
            borderRadius: "5px",
            backgroundColor: enable ? "green" : "lightgray",
            cursor: enable ? "pointer" : "default",
          }}
        >
          {state.clinicId !== undefined ? "Update Clinic" : "Add Clinic"}
        </button>
      </div>
    </Form>
  )
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     addSingleClinic: (organisation) =>
//       dispatch(addSingleClinic(organisation)),
//   }
// }

// export default connect(mapDispatchToProps)(SingleClinic)

// const handleSubscribeChange = (value) => {
//   console.log(value)
//   setState({ ...state, isSubscription: value })
//   setEnable(true)

//   console.log("inside hsc", state)
// }
