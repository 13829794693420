import { navigate } from "gatsby"
import React from "react"
import { Layout } from "../../components/Layout"
import { SingleClinic } from "../../components/SingleClinic"
import { OrganisationsSection as ClinicsSection } from "../../style/organisations"

function Clinic({ location = {} }) {
  if (location.state === null || undefined) {
    // console.log(location.state)
    navigate("/")
    // console.log("Organisation from navigation", location.state.data)
  }

  return (
    <div>
      <Layout>
        <ClinicsSection>
          <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
            <p
              onClick={() => {
                typeof history !== "undefined" && history.go(-1)
              }}
              style={{
                cursor: "pointer",
                // textDecoration: "underline",
                fontSize: "0.7rem",
                marginBottom: "1rem",
              }}
            >
              Clinics
            </p>
            <p
              style={{
                // cursor: "pointer",
                fontSize: "0.9rem",
                marginBottom: ".9rem",
                marginLeft: "1rem",
                marginRight: "1rem",
              }}
            >
              {" "}
              /{/* &#8827; */}
            </p>
            <p
              style={{
                // cursor: "pointer",
                fontSize: "0.7rem",
                marginBottom: "1rem",
              }}
            >
              {location.state &&
              location.state.data &&
              location.state.data.clinicId
                ? "Clinic ID:" + location.state.data.clinicId
                : "Add new clinic"}
            </p>
          </div>
          <h1>
            {location.state && location.state.data
              ? "  Clinic - " + location.state.data.clinicName
              : "Add New Clinic"}
          </h1>
          <SingleClinic clinicData={location.state && location.state.data} />
        </ClinicsSection>
      </Layout>
    </div>
  )
}

export default Clinic

// {
//   "clinicName": "Apollo",
//   "clinicAddress": "Jubilee Hills",
//   "clinicCity": "Hyderabad",
//   "clinicState": "Telangana",
//   "clinicCountry": "India",
//   "clinicPincode": 500032,
//   "clinicPhoneNo": "8179426798",
//   "clinicEmail": "sindhu@zyppys.com",
//   "clinicWebsiteAddress": "https://apolloadmin.askapollo.com/apolloleads/apolloconsultleads?gclid=Cj0KCQiA4feBBhC9ARIsABp_nbWjCL1rnUi2Hcacc5kof9CmnRjyH2FMIsYis8OauKbO4pG-r6h4JoMaAmSZEALw_wcB",
//   "locationLat": 3.1499021,
//   "locationLng": 101.6971338,
//   "clinicOwnerName": "Pratap",
//   "ownerAddress": "Jubilee Hills",
//   "ownerCity": "Hyderabad",
//   "ownerState": "Telangana",
//   "ownerCountry": "India",
//   "ownerPincode": 504208,
//   "ownerPhoneNo": "8179426798",
//   "ownerEmail": "sindhu@zyppys.com",
//   "authorizedPersonName": "Sindhu",
//   "authorizedContactNo": "8372463232",
//   "authorizedEmail": "apollo@india.com",
//   "ownershipType": 100260001,
export const ownershipTypes = [
  {
    lookupId: 100260001,

    lookupDescription: "Central Government",
    lookupValue: "Central Government",
    lookupNotes: null,
    lookupCategoryId: 10026,
  },
  {
    lookupId: 100260002,

    lookupDescription: "State Government",
    lookupValue: "State Government",
    lookupNotes: null,
    lookupCategoryId: 10026,
  },
  {
    lookupId: 100260003,

    lookupDescription: "Local Government",
    lookupValue: "Local Government",
    lookupNotes: null,
    lookupCategoryId: 10026,
  },
  {
    lookupId: 100260004,

    lookupDescription: "Individual Proprietor",
    lookupValue: "Individual Proprietor",
    lookupNotes: null,
    lookupCategoryId: 10026,
  },
  {
    lookupId: 100260005,

    lookupDescription: "Partnership",
    lookupValue: "Partnership",
    lookupNotes: null,
    lookupCategoryId: 10026,
  },
  {
    lookupId: 100260006,

    lookupDescription: "Private Limited",
    lookupValue: "Private Limited",
    lookupNotes: null,
    lookupCategoryId: 10026,
  },
  {
    lookupId: 100260007,

    lookupDescription: "Co-operative Society ",
    lookupValue: "Co-operative Society ",
    lookupNotes: null,
    lookupCategoryId: 10026,
  },
  {
    lookupId: 100260008,

    lookupDescription: "Trust",
    lookupValue: "Trust",
    lookupNotes: null,
    lookupCategoryId: 10026,
  },
]
//   "clinicalServiceType": 100270001,
export const clinicalServiceTypes = [
  {
    lookupId: 100270001,
    lookupDescription: "General",
    lookupValue: "General",
    lookupNotes: null,
    lookupCategoryId: 10027,
  },
  {
    lookupId: 100270002,
    lookupDescription: "Single Speciality ",
    lookupValue: "Single Speciality ",
    lookupNotes: null,
    lookupCategoryId: 10027,
  },
  {
    lookupId: 100270003,
    lookupDescription: "Multi-speciality",
    lookupValue: "Multi-speciality",
    lookupNotes: null,
    lookupCategoryId: 10027,
  },
  {
    lookupId: 100270004,
    lookupDescription: "Super Speciality",
    lookupValue: "Super Speciality",
    lookupNotes: null,
    lookupCategoryId: 10027,
  },
  {
    lookupId: 100270005,
    lookupDescription: "Other",
    lookupValue: "Other",
    lookupNotes: null,
    lookupCategoryId: 10027,
  },
]
//   "inpatient": 100280001
export const inpatientTypes = [
  {
    lookupId: 100280001,
    lookupDescription: "Hospital",
    lookupValue: "Hospital",
    lookupNotes: null,
    lookupCategoryId: 10028,
  },
  {
    lookupId: 100280002,
    lookupDescription: "Nursing Home",
    lookupValue: "Nursing Home",
    lookupNotes: null,
    lookupCategoryId: 10028,
  },
  {
    lookupId: 100280003,
    lookupDescription: "Maternity Home",
    lookupValue: "Maternity Home",
    lookupNotes: null,
    lookupCategoryId: 10028,
  },
  {
    lookupId: 100280004,
    lookupDescription: "Primary Healthcare",
    lookupValue: "Primary Healthcare",
    lookupNotes: null,
    lookupCategoryId: 10028,
  },
  {
    lookupId: 100280005,
    lookupDescription: "Community Healthcare",
    lookupValue: "Community Healthcare",
    lookupNotes: null,
    lookupCategoryId: 10028,
  },
]
// }
